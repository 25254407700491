// Foundation.Interchange.SPECIAL_QUERIES['medium_retina'] = 'only screen and (min-width: 40em), (min-width: 40em) and (-webkit-min-device-pixel-ratio: 2), (min-width: 40em) and (min--moz-device-pixel-ratio: 2), (min-width: 40em) and (-o-min-device-pixel-ratio: 2/1), (min-width: 40em) and (min-device-pixel-ratio: 2), (min-width: 40em) and (min-resolution: 192dpi), (min-width: 40em) and (min-resolution: 2dppx)';

jQuery(document).foundation();

// close off-canvas on menu item click
$('.off-canvas a').click(function () {
  $('.off-canvas').foundation('close');
});


Foundation.Abide.defaults.patterns['phone_number'] = /^[0-9\s.\-/]+$/;

$('#send_request').click(function () {
  $('[data-abide]').foundation('validateForm');
});



class Contact {

  constructor() {
    this.url = '/send_request.php';
    //this.url = '/ppgk/webpage/api/htdocs/send_request.php';
    $(document).on("formvalid.zf.abide", (ev, frm) => { this.form_is_valid(frm) });
  }
  
  form_is_valid(frm) {

    var data = frm.serializeArray();

    data.push({
      name: 'secret',
      value: 'heureka'
    });

    $.ajax({
      method: 'POST',
      url: this.url,
      dataType: 'json',
      data: data,
      success: this.transfer_success.bind(this),
      error: this.transfer_error.bind(this)
    });
  }
  
  transfer_success(result) {
    if (result && result.success) {
      var $modal_success = $('#contact_success');
      $modal_success.find('p').html(result.msg);
      $modal_success.foundation('open');
    } else {
      var $modal_error = $('#contact_error');
      $modal_error.find('p').html(result.msg);
      $modal_error.foundation('open');
    }
  }
  transfer_error(e, msg, err) {
    var $modal_error = $('#contact_error');
    $modal_error.foundation('open');
    console.error(this, e, msg, err);
  }
}

/**
 * obfuscate email & phone
 */
class ObfuscateMailPhone {
  constructor() {
    let a = 'kontakt';
    let d = 'pp';
    let e = 'gk';
    let c = '.d';
    let b = 'e';
    
    let g = '+49 62';
    let f = '1 978';
    let i = '6345';
    let h = '1';
    
    let $mail = $('[data-hide-mail]');
    let $phone_g = $('[data-hide-phone-g]');
    
    $mail.attr('href', `mailto:${a}@${d}${e}${c}${b}`);
    $mail.html(`${a}@${d}${e}${c}${b}`);
    
    $phone_g.attr('href', 'tel:' + (g + f + i + h).replace(/ /g, ''));
    
    let dom_phone_g = $phone_g[0];
    dom_phone_g.textContent = `${g}${f}${i}${h}`;
  }
}

$(document).ready(() => {
  new ObfuscateMailPhone();
  new Contact();
  
});

/**
 * contact a specified therapist using the contact-button
 */

let jdom_therapist_links = $('[data-therapist]');

jdom_therapist_links.map((i, dom) => {
  let jdom = $(dom);
  let therapist_name = jdom.data('therapist');
  jdom.click((e) => {
    $('#therapist').val(therapist_name);
  })
});

/**
 * scroll to top of opened accordion
 */

$(document).on('down.zf.accordion', function (event) {
  let $link = $(event.currentTarget.activeElement);
  if (event.currentTarget.activeElement.tagName === 'BODY') {
    $link = $(event.target);
  }
  let top = $link.offset().top;
  let $menu_sticky_elements = $('[data-sticky]');
  $menu_sticky_elements.map((i, dom) => {
    top -= $(dom).outerHeight() * 1.5;
  });
  if (top > 0)
    $('html, body').animate({scrollTop: top}, 'slow');
});

